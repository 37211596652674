.isOpenList{
  right: 0;
}

.isCloseList{
  right: -500px;
}

.mask {
  position: absolute;
  background: transparent;
  width: 100vw;
  height: calc(100vh - 64px);
  left: 0;
  top: 64px;
}

.notificationList {
  position: fixed;
  top: 64px;
  width: 500px;
  height: auto;
  overflow: hidden;
  background: white;
  transition: right 0.6s;
  padding-top: 8px;
  border: 1px solid #f0f0f0;
}