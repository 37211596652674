.modal {
  :global {
    .ant-form-item-label {
      min-width: 140px;
      max-width: 140px;
      text-align: left;
    }

    .ant-typography {
      margin-bottom: 24px;
    }
  }
}

.code_field {
  width: 65%;
}
