.notification_container {
  padding: 8px 24px;
  position: relative;
}

.wrap {
  max-height: 435px;
  overflow-y: auto;
}

.close_button {
  position: absolute;
  right: 8px;
  top: 8px;
  :global {
    span {
      font-size: 12px;
    }
  }
}