.disables-column .ant-typography {
  color: darkgray;
}

.ant-collapse{
  border: none;
}

.ant-collapse .ant-collapse-item{
  border: none;
}

.ant-collapse-content > .ant-collapse-content-box{
  padding: 24px 0;
}

.ant-notification {
  white-space: pre-line;
}

.page-wrap {
  display: flex;
  padding: 24px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1;
}

.ant-typography, .ant-table-cell {
  font-family: Arial;
  color: black;
}

.custom-table tr > td {
  padding: 10px 16px;
}

.search-wrap {
  padding: 8px;
}

.secondary-button {
  color: #fff;
  background: #096DD9;
  border-color: #096DD9;
  margin-left: 2px;
  &:hover, &:focus {
    background: rgba(#096DD9, 0.8);
    border-color: rgba(#096DD9, 0.8);
  }
}
