.wrap {
  height: 64px;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  padding: 4px 24px;
}

.clear_text {
  :global{
    span {
      text-decoration: underline;
    }
  }
}