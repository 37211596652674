.logo {
  font-size: 30px;
  font-weight: 600;
  align-items: center;
  display: flex;
  :global {
    span {
      font-size: inherit;
    }
    .ant-typography {
      color: #FFFFFF;
    }
  }
}