.modal {
  :global {
    .ant-form-horizontal .ant-form-item-label {
      min-width: 80px;
      text-align: left;
    }
    .ant-form-item{
      margin: 5px 0;
    }
    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
    }
  }
}