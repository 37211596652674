.modal{
  min-width: 600px;

  :global {
    .ant-card-head{
      background-color: #f0f0f0;
    }
    .ant-form-horizontal .ant-form-item-label {
      min-width: 110px;
      text-align: left;
    }
    .ant-form-item{
      margin: 5px 0;
    }
    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

