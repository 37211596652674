.wrap {
  background: #2f54eb;
  height: 64px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 0 24px;
  display: flex;
  @media screen and (max-width: 768px) {
    position: relative;
    height: auto;
    padding: 20px 0 20px 0;
    border-bottom: none;
  }
  :global {
    .ant-menu-dark {
      display: flex;
      background: transparent;
      height: 64px;
      :global {
        .ant-menu-item-selected {
          background: #003EB3;
        }
      }
    }
    .ant-menu-item {
      align-items: center;
      display: flex;
      color: #FFFFFF;
      font-size: 16px;
    }
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81.78px;
  height: 64px;
  font-size: 16px;
  text-decoration: underline;
  color: #FFFFFF;
  &:hover {
    color: #ffffffb3;
    text-decoration: underline;
  }
}

.item {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: 500px;
  margin-left: auto;
  align-items: center;
  @media (min-width: 1440px) {
    height: 64px;
  }
  @media screen and (max-width: 768px) {
    display: block;
    text-align: center;
  }
  button {
    border: none;
    line-height: 2.2;
    background: none;
  }

  :global{
    .ant-typography{
      color: #FFFFFF;
      line-height: 1.5;
    }
  }
}
