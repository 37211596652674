.title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 24px;
}

.modal {
  :global {
    .ant-form-item-label {
      min-width: 140px;
      max-width: 140px;
      text-align: left;
    }

    .ant-typography {
      margin-bottom: 24px;
    }
  }
}